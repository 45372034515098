import { Box, Typography } from "@mui/material";
import { importClients } from "api/services/clients/clients";
import DrawerWrapper from "components/DrawerWrapper";
import FileDrop from "components/FileDrop";
import LoadingButton from "components/LoadingButton";
import { snack } from "components/toast";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { DialogProps } from "types";
import { FILETYPES } from "data/constants";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { exportErrorList } from "api/services/reports";
import Loader from "components/Loader";

interface Props extends DialogProps {
  successCb?: () => void;
}

function ImportClients({ open, setOpen, successCb }: Props) {
  const queryClient = useQueryClient();
  const [file, setFile] = useState<File | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => {
    setOpenModal(!openModal);
    // setOpen(false);
  };
  let [errorsListModal, setErrorsListModal]: any = useState([]);

  const { mutate, isLoading } = useMutation(importClients, {
    onSuccess: (e) => {
      setFile(null);
      const errorsArray: any = e.data;
      if (errorsArray.length > 2) {
        const errorsList = errorsArray.slice(0, errorsArray.length - 2);
        setErrorsListModal(errorsList);
        handleOpen();
        const lastItem = errorsArray[errorsArray.length - 1];
        if (lastItem !== 0) {
          snack.success(`${lastItem} Clients imported successfully`);
        }
        const lastButOneItem = errorsArray[errorsArray.length - 2];
        if (lastButOneItem !== 0) {
          snack.error(`${lastButOneItem} clients are not imported`);
        }
        if (lastItem) {
          successCb && successCb();
        }
      } else {
        const clientNumber = errorsArray[1];
        if (clientNumber !== 0) {
          snack.success(`${clientNumber} Clients imported successfully`);
        }
        const notAddedClients = errorsArray[0];
        if (notAddedClients !== 0) {
          snack.error(`${notAddedClients} clients are not imported`);
        }
        setOpen(false);
        if (clientNumber) {
          successCb && successCb();
        }
      }
      queryClient.invalidateQueries("clients");
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });
  const { mutate: getErrorFile, isLoading: loading } = useMutation(exportErrorList, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = file;
      link.download = "Clients Import Errors.xlsx";
      link.click();
      snack.success("Exported successfully");
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });

  const handleExport = () => {
    getErrorFile(errorsListModal);
  };

  const handleFiles = (files: File[]) => {
    setFile(files[0]);
  };

  const handleSubmit = () => {
    if (!file) {
      snack.error("Select a file");
      return;
    }

    const ValidFile = (): boolean => {
      return (
        file.type === FILETYPES.SHEET1 ||
        file.type === FILETYPES.SHEET2 ||
        file.type === FILETYPES.SHEET3
      );
    };

    if (!ValidFile()) {
      snack.error("Invalid File Type, Only .xls or .xlsx files are accepted");
      return;
    }

    let formData = new FormData();
    formData.append("file", file);
    mutate(formData);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <DrawerWrapper open={open} setOpen={setOpen} title="Import Clients">
      <FileDrop onChange={handleFiles} />
      <Box display="flex" justifyContent="space-between">
        <Typography variant="caption" color="rgba(0,0,0,0.7)">
          *Only .xls or .xlsx files are accepted
        </Typography>
        <a
          style={{ textDecoration: "none" }}
          // href="https://jss-vider.s3.ap-south-1.amazonaws.com/clients-import.xlsx"
          href="https://jss-vider.s3.ap-south-1.amazonaws.com/ATOM%2B-%2BClients%2BImport.xlsx"
        >
          <Typography variant="caption" color="secondary">
            Download Sample File
          </Typography>
        </a>
      </Box>
      <LoadingButton
        onClick={handleSubmit}
        variant="contained"
        disabled={isLoading}
        loading={isLoading}
        sx={{ mt: 3 }}
        fullWidth
        color="secondary"
        title="Import Clients"
      />
      {isLoading ? (
        <Modal
          open={isLoading}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              zIndex: "100",
              height: "30vh",
              width: "60vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "center",
              overflow: "auto",
            }}
          >
            <Typography textAlign={"center"} variant="h6">Creating Clients</Typography>
            <Typography textAlign={"center"} sx={{ marginTop: "8px" }}>
              Thanks for your patience! We're currently importing a large number of new clients.
              This may take a few minutes. Please wait while the import completes and avoid
              refreshing the page to prevent errors.
            </Typography>
            <Loader minHeight={"70px"} />
          </Box>
        </Modal>
      ) : null}
      {!isLoading ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, maxHeight: "80vh", width: "50vw", overflow: "auto" }}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            Client Bulk Import Report
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <ol>
                {errorsListModal.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ol>
            </Typography>
            <Box>
              <Button variant="outlined" color="secondary" sx={{ m: 2 }} onClick={handleClose}>
                Close
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleExport}>
                Export Errors to Excel
              </Button>
            </Box>
          </Box>
        </Modal>
      ) : null}
    </DrawerWrapper>
  );
}

export default ImportClients;

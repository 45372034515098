import { http } from "api/http";
import axios from "axios";

const getSandboxToken = () => {
  // return http.post("/sandbox/token", {});
  return axios({
    url: process.env.REACT_APP_SANDBOX_AUTH_URL || "",
    method: "POST",
    headers: {
      Accept: "application/json",
      "x-api-key": process.env.REACT_APP_SANDBOX_API_KEY || "",
      "x-api-secret": process.env.REACT_APP_SANDBOX_API_SECRET || "",
      "x-api-version": "1.0",
    },
  });
};

const getGstDetails = ({ token, gstNumber }) => {
  return http.post("/sandbox/gstdetails", { token, gstNumber });
  // return axios.get(`${process.env.REACT_APP_SANDBOX_GST_API}/${gstNumber}`, {
  //   headers: {
  //     Authorization: token,
  //     "x-api-key": process.env.REACT_APP_SANDBOX_API_KEY || "",
  //   },
  // });
};

const fynGstDetails = ({ gstNumber }) => {
  return http.post("sandbox/fyngst", { gstNumber });
};

const getPanDetails = ({ token, panNumber }) => {
  return http.post("/sandbox/pandetails", { token, panNumber });
  // return axios.get(`${process.env.REACT_APP_SANDBOX_PAN_API}/${panNumber}`, {
  //   headers: {
  //     Authorization: token,
  //     "x-api-key": process.env.REACT_APP_SANDBOX_API_KEY || "",
  //   },
  //   params: {
  //     consent: "y",
  //     reason: "For KYC of User",
  //   },
  // });
};

const signup = (data: any) => {
  return http.post("/users/signup", data);
};

const sendOtp = (data: any) => {
  return http.post("/users/otp", data);
};

const verifyOtp = (data: any) => {
  return http.post("/users/otp-verify", data);
};

const signin = (data: any) => {
  return http.post("/users/signin", data);
};

const forgotPassword = (data: any) => {
  return http.post("/users/forgot-password", data);
};

const forgotPasswordOtp = (data: any) => {
  return http.post("users/forgotpassword-otp", data);
};

const resetPassword = (data: any) => {
  return http.post("/users/reset-password", data);
};

const inviteUser = (data: any) => {
  return http.post("/users/invite-user", data);
};

const editInviteUser = (data: any) => {
  return http.patch("/users/invite-user/edit", data);
};

const joinUser = (data: any) => {
  return http.post("/users/join", data);
};

const getUsers = () => {
  return http.get("/users");
};

const getUsersAvail = ({ queryKey }: any) => {
  return http.get("users/getAval", { params: { ...queryKey[1] } }) // Pass params as query parameters
};

const getUsersAvailUser = ({ queryKey }: any) => {
  return http.get("users/getAvalUser", { params: { ...queryKey[1] } }) // Pass params as query parameters
};

const getUsersForReport = () => {
  return http.get("/users/reports");
};

const getAllUsers = ({ queryKey }) => {
  const [, params] = queryKey; // Destructure to extract parameters
  console.log("Query Parameters:", params); // Improved logging for clarity
  return http.get("/users/all", { params }); // Pass the extracted params directly
};

const getAllUsersInOrganzaition = () => {
  return http.get("/users/all-organization-users");
};

const getInvitedusers = ({ queryKey }) => {
  return http.get("/users/invited", { params: { ...queryKey[1] } });
};

const cancelInvitation = (id: number) => {
  return http.post(`/users/invited/${id}/cancel`);
};

const resendInvitation = (id: number) => {
  return http.post(`/users/invited/${id}/resend`);
};

const getTeams = () => {
  return http.get("/teams");
};

const getTeam = ({ queryKey }) => {
  return http.get(`/teams/${queryKey[1]}`);
};

const createTeam = (data: any) => {
  return http.post("/teams", data);
};

const removeFromTeam = ({ teamId, userId }) => {
  return http.post(`/teams/${teamId}/remove-member`, { userId });
};

const updateTeam = ({ id, data }) => {
  return http.put(`/teams/${id}`, data);
};

const deleteTeam = ({ id }) => {
  return http.delete(`/teams/${id}`);
};

const getProfile = () => {
  return http.get("/users/profile");
};

const getUserProfile = ({ queryKey }) => {
  return http.get(`/users/profile/${queryKey[1]}`);
};

const getUserOrganizationCheck = ({ queryKey }) => {
  return http.get(`/users/check/${queryKey[1]}`);
};

const updateProfile = (data: any) => {
  return http.patch(`/users/profile`, data);
};

const uploadProfile = ({ data }) => {
  return http.put(`/users/profile/image`, data);
};

const updateUserProfile = ({ id, data }: any) => {
  return http.patch(`/users/profile/${id}`, data);
};

const changePassword = (data: any) => {
  return http.patch(`/users/change-password`, data);
};

const deleteUser = (id: number) => {
  return http.post(`/users/${id}/delete`);
};

const activateDeactivateUser = ({ id, data }) => {
  return http.post(`/users/${id}/activate-deactivate`, data);
};

const getDeletedUsers = ({ queryKey }) => {
  let { limit, offset, sort, search, query } = queryKey[1] || {};

  return http.get("/users/deleted", {
    params: {
      limit,
      offset,
      sort,
      search,
      ...query,
    },
  });
};

const restoreUser = ({ id, data }) => {
  return http.post(`/users/${id}/restore`, data);
};

const importUsers = (data: FormData) => {
  return http.post(`/users/import`, data);
};

const createNonOrganizationUser = (data: any) => {
  return http.post("users/create-non-org", data);
};

const updateNonOrganizationUser = ({ id, data }: { data: any; id: any }) => {
  return http.put(`/users/non-org-user/${id}`, data);
};

const deleteNonOrganizationUser = (id: any) => {
  return http.delete(`/users/non-org-user/${id}`);
};

const getNonOrganizationUsers = () => {
  return http.get("users/non-org-users");
};

const getOrgAndNonOrgUsers = () => {
  return http.get("/users/all-users");
};

const getDeleteUserData = ({ queryKey }) => {
  return http.get(`/users/delete-data/${queryKey[1]}`);
};

export {
  // getSandboxToken,
  // getGstDetails,
  // getPanDetails,
  signup,
  signin,
  inviteUser,
  getUsers,
  sendOtp,
  verifyOtp,
  joinUser,
  getTeams,
  createTeam,
  updateTeam,
  deleteTeam,
  getTeam,
  removeFromTeam,
  getProfile,
  updateProfile,
  forgotPassword,
  resetPassword,
  changePassword,
  getInvitedusers,
  getUserProfile,
  updateUserProfile,
  deleteUser,
  activateDeactivateUser,
  getAllUsers,
  getDeletedUsers,
  restoreUser,
  cancelInvitation,
  resendInvitation,
  importUsers,
  getUsersForReport,
  editInviteUser,
  fynGstDetails,
  forgotPasswordOtp,
  getUserOrganizationCheck,
  createNonOrganizationUser,
  updateNonOrganizationUser,
  deleteNonOrganizationUser,
  getNonOrganizationUsers,
  getOrgAndNonOrgUsers,
  uploadProfile,
  getUsersAvail,
  getUsersAvailUser,
  getDeleteUserData,
  getAllUsersInOrganzaition,
};

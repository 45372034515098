import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Table, { ColumnType } from "components/Table";
import useTitle from "hooks/useTitle";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ResType } from "types";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetFilters } from "redux/reducers/taskboardSlice";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { formatToRupeess } from "utils/formattedAmount";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ClientsHeader from "views/atom-pro/ClientsHeader";
import { LinkRouter } from "components/BreadCrumbs";
import { Visibility } from "@mui/icons-material";
import moment from "moment";
import {
  exportIncomeTaxEproceedingsFyi,
  getFyiSections,
  getIncomeTaxEproceedingsFyi,
} from "api/services/automation/income-tax";
import { clientCategories, getAutFinancialYears, sectionCodeList } from "pages/atom-pro/constants";
import SearchContainer from "components/SearchContainer";
import Loader from "components/Loader";
import CategoryIcon from "@mui/icons-material/Category";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { getCurrentFormattedDateTime } from "utils";
import { snack } from "components/toast";
import { handleError } from "utils/handleError";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const EproceedingFyi = (tab) => {
  const [active, setActive] = useState("FYA");
  useTitle("ATOM Pro | e-Proceedings");
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const dispatch = useDispatch();
  const [section, setSection] = useState<any>(null);
  const [assessmentYear, setAssessmentYear] = useState<any>(null);
  const [clientCategory, setClientCategory] = useState<any>(null);
  const [sections, setSectionData] = useState<any>(null);
  const location = useLocation();
  const state: any = location.state;
  const [type, setType] = useState<any>(null);

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  const { data, isLoading }: ResType = useQuery(
    [
      "e-proceedings",
      {
        search: search,
        limit: pageCount,
        offset: page * pageCount,
        section: section,
        assessmentYear: assessmentYear,
        clientCategory: clientCategory,
        type: type,
      },
    ],
    getIncomeTaxEproceedingsFyi,
    {
      onSuccess: (res) => {
        setTotalCount(res?.data?.count);
      },
    }
  );

  const { data: sectionData, isLoading: sectionIsloading }: ResType = useQuery(
    ["e-proceedings-sections"],
    getFyiSections,
    {
      onSuccess: (res) => {
        setSectionData(res?.data);
      },
    }
  );

  const onChange = (e: any) => {
    setSection(e.target.value);
    setPage(0);
  };

  const onAssessmentYearChange = (e: any) => {
    setAssessmentYear(e.target.value);
    setPage(0);
  };

  const onClientCategoryChange = (e: any) => {
    setClientCategory(e.target.value);
  };

  const handleSearch = (e) => {
    setSearch(e);
    setPage(0);
  };

  const onTypeChange = (e: any) => {
    setType(e.target.value);
    setPage(0);
  };

  const { mutate: mutate1 } = useMutation(exportIncomeTaxEproceedingsFyi, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      const formattedDateTime = getCurrentFormattedDateTime();

      let link = document.createElement("a");
      link.href = file;
      link.download = `ATOM Pro - Income Tax (PAN) e-Proceedings (For Your Information) ${formattedDateTime}.xlsx`;
      link.click();
      snack.success("Income Tax (PAN) e-proceedings exported sucessfully");
    },
    onError: (err: any) => {
      snack.error(err);
      snack.error(handleError(err));
    },
  });
  const handleExport = () => {
    mutate1({
      ...state,
      search: search,
      limit: pageCount,
      offset: page * pageCount,
      section: section,
      assessmentYear: assessmentYear,
      clientCategory: clientCategory,
    });
  };

  if (isLoading || sectionIsloading) return <Loader />;

  return (
    <>
      <Box m={1}>
        {/* <Breadcrumbs>
        <LinkRouter underline="hover" color="inherit" to="/atom-pro/income-tax">
          Atom Pro
        </LinkRouter>
        <Typography>e-Proceedings</Typography>
      </Breadcrumbs> */}

        <Box mt={1}>
          {/* <ClientsHeader search={search} setSearch={setSearch} /> */}
          <Box mb={1} display="flex" alignContent="center">
            <TextField
              name="assessmentYear"
              value={assessmentYear}
              onChange={onAssessmentYearChange}
              size="small"
              label="Assessment Year"
              select
              sx={{ width: "150px", marginRight: "20px" }}
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              <MenuItem key={""} value={"null"}>
                NA
              </MenuItem>
              {getAutFinancialYears().map((year: string) => (
                <MenuItem key={year} value={year.split("-")[0]}>
                  {year}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              name="clientCategory"
              value={clientCategory}
              onChange={onClientCategoryChange}
              size="small"
              label="Category"
              select
              sx={{ width: "230px", marginRight: "20px" }}
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              {clientCategories.map((category) => (
                <MenuItem key={category.value} value={category.value}>
                  {category.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              name="section"
              value={section}
              onChange={onChange}
              size="small"
              label="Section"
              select
              sx={{ width: "100px", marginRight: "20px" }}
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              <MenuItem key={""} value={"null"}>
                NA
              </MenuItem>
              {sections?.map((option: any, index: any) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              name="type"
              value={type}
              onChange={onTypeChange}
              size="small"
              label="Type"
              select
              sx={{ width: "100px", marginRight: "20px" }}
            >
              <MenuItem key={""} value={""}>
                Select
              </MenuItem>
              {["Self", "Other"]?.map((option: any, index: any) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <SearchContainer
              debounced
              value={search}
              placeHolder="Search by Client | PAN | Proceeding Name"
              onChange={handleSearch}
            />
            <Button
              sx={{ marginLeft: 2 }}
              onClick={() => {
                setSection("");
                setAssessmentYear(null);
                setClientCategory(null);
                setSearch("");
              }}
              size="small"
              color="secondary"
              variant="outlined"
            >
              Clear Filters
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleExport}
              startIcon={<FileDownloadOutlinedIcon />}
              sx={{ marginLeft: 2 }}
            >
              Export
            </Button>
            <Box>{/* <ClientsHeader search={search} setSearch={setSearch} /> */}</Box>
          </Box>
          <Table
            sx={{ pb: 0, mb: 0, height: "475px" }}
            pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
            data={data?.data.result || []}
            columns={columns}
            loading={isLoading}
            // onRowClick={handleRowClick}
          />
        </Box>
      </Box>
    </>
  );
};

function generateRandomColor(index) {
  const colors = ["#ff9595", "#EDE7FB", "#FBE7E9", "#FCF4C5", "#EEFCC9", "#F6F6F6", "#EAFFE7"];
  const colorIndex = index % colors.length;
  return colors[colorIndex];
}

const generateAssessmentYear = (assessmentYear) => {
  if (!assessmentYear || assessmentYear === "0" || assessmentYear === "") {
    return "NA";
  } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
    const year = parseInt(assessmentYear);
    const nextYear = (year + 1).toString().slice(-2);
    return `AY ${year}-${nextYear}`;
  } else {
    return assessmentYear;
  }
};

const getCategoryBackgroundColor = (category) => {
  switch (category) {
    case "individual":
      return "#FCF4C5";
    case "huf":
      return "#E0F7FA";
    case "partnership_firm":
      return "#FFE0B2";
    case "llp":
      return "#D1C4E9";
    case "company":
      return "#C8E6C9";
    case "aop":
      return "#FFCCBC";
    case "boi":
      return "#C5CAE9";
    case "trust":
      return "#F8BBD0";
    case "government":
      return "#B2DFDB";
    case "local_authority":
      return "#FFD54F";
    case "artificial_judicial_person":
      return "#F0F4C3";
    case "corporations":
      return "#D7CCC8";
    default:
      return "#FFFFFF";
  }
};

const columns: Array<ColumnType> = [
  {
    key: "assessmentYear",
    title: "Assessment Year",
    render: (v) => <Typography>{generateAssessmentYear(v?.assessmentYear)}</Typography>,
  },
  { key: "type", title: "Type" },
  {
    key: "",
    title: (
      <Box display="flex" alignItems="center">
        {/* <CategoryIcon style={{ width: "20px", marginRight: "5px" }} /> */}
        Category
      </Box>
    ),
    render: (v) => {
      const categoryLabel =
        clientCategories.find((category) => category.value === v?.client?.category)?.label || "";
      const backgroundColor = getCategoryBackgroundColor(v?.client?.category);

      return <Typography>{categoryLabel}</Typography>;
    },
  },

  {
    key: "",
    title: (
      <Box display="flex" alignItems="center">
        {/* <PersonOutlineOutlinedIcon style={{ width: "20px", marginRight: "5px" }} /> */}
        Client Name (PAN)
      </Box>
    ),
    render: (v) => (
      <Typography>
        {v?.client?.displayName}(<span>{v?.client?.autClientCredentials?.[0]?.panNumber}</span>)
      </Typography>
    ),
  },

  {
    key: "noticeName",
    title: (
      <Box display="flex" alignItems="center">
        Section
      </Box>
    ),
    render: (v) => <Typography>{v?.noticeName ? <span>{v.noticeName}</span> : "--"}</Typography>,
  },
  {
    key: "sectionCodeText",
    title: "Proceeding Name",
    render: (v) => <Typography>{v.proceedingName}</Typography>,
  },
  {
    key: "",
    title: (
      <Box display="flex" alignItems="center">
        {/* <CategoryIcon style={{ width: "20px", marginRight: "5px" }} /> */}
        Name Of Assesse
      </Box>
    ),
    render: (v) => {
      return (
        <Typography>{v?.notices?.length > 0 ? v?.notices[0]?.nameOfAssesse : "--"}</Typography>
      );
    },
  },
  {
    key: "",
    title: "Action",
    render: (rowData: any) => <Actions data={rowData} />,
  },
];

function Actions(props: any) {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate(`/atom-pro/income-tax/proceeding-information/${props.data?.id}`);
  };

  return (
    <IconButton onClick={handleViewMoreClick} size="small">
      <Visibility />
    </IconButton>
  );
}

export default EproceedingFyi;

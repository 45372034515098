import { Box, Button, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import Table, { ColumnType } from "components/Table";
import useTitle from "hooks/useTitle";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ResType } from "types";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetFilters } from "redux/reducers/taskboardSlice";
import { Visibility } from "@mui/icons-material";
import {
  exportClientproceedingFya,
  getClientproceedingFya,
  getExcelFyaSections,
  getIncomeTaxProfile,
} from "api/services/automation/income-tax";
import AccessDenied from "./Access/accessDenide";
import Loader from "components/Loader";
import { resetState } from "redux/reducers/clientsSlice";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { getCurrentFormattedDateTime } from "utils";
import { link } from "fs";
import { handleError } from "utils/handleError";
import { snack } from "components/toast";
import DateRange from "views/dashboard/OrgDashboard/DateRange";
import SearchContainer from "components/SearchContainer";
import { getAutFinancialYears } from "pages/atom-pro/constants";

const ClientEproceedingFya = (state) => {
  useTitle("ATOM Pro | Clients");
  const [page, setPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const dispatch = useDispatch();
  const params = useParams();
  const [section, setSection] = useState<any>(null);
  const [assessmentYear, setAssessmentYear] = useState<any>(null);
  const [sections, setSectionData] = useState<any>(null);
  const [type, setType] = useState<any>(null);
  const [dates, setDates] = useState({ fromDate: null, toDate: null });
  const [search, setSearch] = useState("");

  useEffect(() => {
    return () => {
      dispatch(resetFilters());
      dispatch(resetState());
    };
  }, []);

  const [checkAccess, setCheckAccess] = useState(true);

  const { data, isLoading }: ResType = useQuery(
    [
      "client",
      params.incometaxid,
      {
        limit: pageCount,
        offset: page * pageCount,
        section: section,
        assessmentYear: assessmentYear,
        type: type,
        search: search,
        // ...dates,
      },
    ],
    getClientproceedingFya,
    {
      onSuccess: (res) => {
        setTotalCount(res?.data?.count);
        setCheckAccess(res?.data?.accessDenied);
      },
    }
  );

  const onChange = (e: any) => {
    setSection(e.target.value);
    setPage(0);
  };

  const onTypeChange = (e: any) => {
    setType(e.target.value);
    setPage(0);
  };

  const onAssessmentYearChange = (e: any) => {
    setAssessmentYear(e.target.value);
    setPage(0);
  };

  const handleSearch = (e) => {
    setSearch(e);
    setPage(0);
  };

  const { data: sectionData, isLoading: sectionIsloading }: ResType = useQuery(
    ["excel-e-proceedings-sections"],
    getExcelFyaSections,
    {
      onSuccess: (res) => {
        setSectionData(res?.data);
      },
    }
  );

  const { data: profiledata, isLoading: isprofileloading }: ResType = useQuery(
    ["get-income-tax-profile", params.incometaxid],
    getIncomeTaxProfile
  );
  const clientName = profiledata?.data?.profileDetails?.name;
  const { mutate: mutate1 } = useMutation(exportClientproceedingFya, {
    onSuccess: (res: any) => {
      const arr = new Uint8Array(res.data?.data);
      const blob = new Blob([arr], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const file = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      const formattedDateTime = getCurrentFormattedDateTime();
      link.href = file;
      link.download = `ATOM Pro - ${clientName} Income Tax (PAN) e-Proceedings (For Your Action) ${formattedDateTime}.xlsx`;
      link.click();
      snack.success("Income Tax (PAN) e-proceedings exported sucessfully");
    },
    onError: (err: any) => {
      snack.error(err);
      snack.error(handleError(err));
    },
  });

  const handleExport = () => {
    mutate1({
      incometaxid: params.incometaxid,
      ...state,
    });
  };

  if (isLoading) return <Loader />;
  return (
    <>
      <Box m={1}>
        {!checkAccess && <AccessDenied />}
        {checkAccess && (
          <>
            <Box mb={1} display="flex" alignContent="center">
              <TextField
                name="assessmentYear"
                value={assessmentYear}
                onChange={onAssessmentYearChange}
                size="small"
                label="Assessment Year"
                select
                sx={{ width: "150px", marginRight: "20px" }}
              >
                <MenuItem key={""} value={""}>
                  Select
                </MenuItem>
                <MenuItem key={""} value={"null"}>
                  NA
                </MenuItem>
                {getAutFinancialYears().map((year: string) => (
                  <MenuItem key={year} value={year.split("-")[0]}>
                    {year}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                name="section"
                value={section}
                onChange={onChange}
                size="small"
                label="Section"
                select
                sx={{ width: "100px", marginRight: "20px" }}
              >
                <MenuItem key={""} value={""}>
                  Select
                </MenuItem>
                <MenuItem key={""} value={"null"}>
                  NA
                </MenuItem>
                {sections?.map((option: any, index: any) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                name="type"
                value={type}
                onChange={onTypeChange}
                size="small"
                label="Type"
                select
                sx={{ width: "100px", marginRight: "20px" }}
              >
                <MenuItem key={""} value={""}>
                  Select
                </MenuItem>
                {["Self", "Other"]?.map((option: any, index: any) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <SearchContainer
                debounced
                value={search}
                placeHolder="Search by Proceeding Name"
                onChange={handleSearch}
              />
              {/* <Box ml={1}>
                <DateRange dates={dates} setDates={setDates} setPage={setPage} />
              </Box> */}
              <Button
                sx={{ marginLeft: 2 }}
                onClick={() => {
                  setSection("");
                  setAssessmentYear(null);
                  setSearch("");
                  setDates({ fromDate: null, toDate: null });
                }}
                size="small"
                color="secondary"
                variant="outlined"
              >
                Clear Filters
              </Button>
              {/* <Button variant="outlined" color="secondary" onClick={handleExport}
                                startIcon={<FileDownloadOutlinedIcon />}
                                sx={{ marginLeft: 2 }}
                              >
                                Export
                              </Button> */}
              <Box>{/* <ClientsHeader search={search} setSearch={setSearch} /> */}</Box>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleExport}
                startIcon={<FileDownloadOutlinedIcon />}
              >
                Export
              </Button>
            </Box>
            <Table
              sx={{ pb: 0, mb: 0, height: "440px" }}
              pagination={{ totalCount, pageCount, setPageCount, page, setPage }}
              data={data?.data?.result || []}
              columns={columns}
              loading={isLoading}
            />
          </>
        )}
      </Box>
    </>
  );
};

const generateAssessmentYear = (assessmentYear) => {
  if (!assessmentYear || assessmentYear === "0" || assessmentYear === "") {
    return "NA";
  } else if (!isNaN(assessmentYear) && assessmentYear.length === 4) {
    const year = parseInt(assessmentYear);
    const nextYear = (year + 1).toString().slice(-2);
    return `AY ${year}-${nextYear}`;
  } else {
    return assessmentYear;
  }
};

const columns: Array<ColumnType> = [
  {
    key: "assesmentYear",
    title: "Assessment Year",
    render: (v) => <Typography> {generateAssessmentYear(v?.assesmentYear)} </Typography>,
  },
  { key: "type", title: "Type" },
  {
    key: "noticeName",
    title: "Section",
    render: (v) => (v?.noticeName ? <Typography>{v.noticeName}</Typography> : "--"),
  },
  {
    key: "sectionCodeText",
    title: "Proceeding Namee",
    render: (v) => <Typography>{v.proceedingName}</Typography>,
  },

  {
    key: "",
    title: "Actions",
    render: (rowData: any) => <Actions data={rowData} />,
  },
];

function Actions(props: any) {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate(`/atom-pro/income-tax/proceeding-action/${props.data?.id}`);
  };

  return (
    <IconButton onClick={handleViewMoreClick} size="small">
      <Visibility />
    </IconButton>
  );
}

export default ClientEproceedingFya;

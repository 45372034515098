import { v4 as uuidv4 } from "uuid";

export const FREQUENCY_TEXTS = ["Monthly", "Quarterly", "Half-Yearly", "Yearly", "Custom"];

export const MONTHS: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "December",
];

export const QPERIODS: string[] = [
  "Q1 (April - June)",
  "Q1 (July - Sep)",
  "Q1 (October - December)",
  "Q1 (January - March)",
];

export const HPERIODS: string[] = ["H1 (April - June)", "H2 (July - September)"];

export const YPERIODS: string[] = ["Y1"];

export const INITIAL_MILESTONES = [
  {
    name: "Checklist",
    checklist: [],
    id: uuidv4(),
  },
  {
    name: "Stage of Work",
    checklist: [],
    id: uuidv4(),
  },
  {
    name: "Deliverables",
    checklist: [],
    id: uuidv4(),
  },
];

export const FIELD_TYPES: Array<{ label: string; value: string }> = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Multiline",
    value: "multiline",
  },
  {
    label: "Multiselect",
    value: "multiselect",
  },
  {
    label: "Dropdown",
    value: "dropdown",
  },
  {
    label: "Radio",
    value: "radio",
  },
  {
    label: "Checkbox",
    value: "checkbox",
  },
  {
    label: "URL",
    value: "url",
  },
  {
    label: "Password",
    value: "password",
  },
  {
    label: "File",
    value: "file",
  },
];

export const serviceViewMenu: Array<{ title: string; path: string }> = [
  { title: "Dashboard", path: "/dashboard" },
  { title: "Overview", path: "/overview" },
  { title: "Insights", path: "/insights" },
];

export const clientMenu: Array<{ title: string; path: string }> = [
  {
    title: "Profile",
    path: "/profile",
  },
  {
    title: "KYB Info",
    path: "/kyb-info",
  },
  {
    title: "Credentials",
    path: "/credentials",
  },
  {
    title: "DSC Register",
    path: "/dsc-register",
  },
  {
    title: "Pending Tasks",
    path: "/client-tasks",
  },
  {
    title: "Archives",
    path: "/archives",
  },
  {
    title: "Upcoming Tasks",
    path: "/upcoming-tasks",
  },
  {
    title: "Recurring Profile",
    path: "/recurring-profile",
  },
  {
    title: "GST Compliance",
    path: "/compliance",
  },
  {
    title: "Documents",
    path: "/attachments",
  },
  {
    title: "Activity Log",
    path: "/client-activitylog",
  },
  {
    title: "Quantum",
    path: "/client-quantum-documents",
  },
  {
    title: "Permissions",
    path: "/roles-permissions",
  },
];

export const clientGroupMenu: Array<{ title: string; path: string }> = [
  {
    title: "Group Profile",
    path: "/group-profile",
  },
  {
    title: "KYB Info",
    path: "/kyb-info",
  },
  {
    title: "Credentials",
    path: "/credentials",
  },
  {
    title: "DSC Register",
    path: "/dsc-register",
  },
  {
    title: "Pending Tasks",
    path: "/client-tasks",
  },
  {
    title: "Archives",
    path: "/archives",
  },
  {
    title: "Upcoming Tasks",
    path: "/upcoming-tasks",
  },
  {
    title: "Recurring Profile",
    path: "/recurring-profile",
  },
  {
    title: "GST Compliance",
    path: "/compliance",
  },
  {
    title: "Documents",
    path: "/attachments",
  },
  {
    title: "Activity Log",
    path: "/client-activitylog",
  },
  {
    title: "Quantum",
    path: "/client-quantum-documents",
  },
];

export const invoicingClientsMenu: Array<{ title: string; path: string }> = [
  {
    title: "Overview",
    path: "overview",
  },
  {
    title: "Unbilled Tasks",
    path: "unbilledtasks",
  },
  {
    title: "Billed Tasks",
    path: "billedtasks",
  },
  {
    title: "Proforma Invoice",
    path: "proforma",
  },
  {
    title: "Invoices",
    path: "billing",
  },

  // {
  //   title: "Payments received",
  //   path: "/payments-received",
  // },
  // {
  //   title: "Comments",
  //   path: "/comments",
  // },
  // {
  //   title: "Mails",
  //   path: "/mails",
  // },
  // {
  //   title: "Statements",
  //   path: "/statements",
  // },
  {
    title: "Receipts",
    path: "clientreceipts",
  },
  {
    title: "Activity",
    path: "activity",
  },
  // {
  //   title: "Comments",
  //   path: "/comments",
  // },
  // {
  //   title: "Mails",
  //   path: "/mails",
  // },
  // {
  //   title: "Statements",
  //   path: "/statements",
  // },
];

export const userProfileMenu: Array<{ title: string; path: string }> = [
  {
    title: "Profile",
    path: "/profile",
  },
  {
    title: "Pending Tasks",
    path: "/tasks",
  },
  {
    title: "Archives",
    path: "/archives",
  },
  {
    title: "Upcoming Tasks",
    path: "/upcoming-tasks",
  },
  {
    title: "Expenditure",
    path: "/expenditure",
  },
  // {
  //   title: "Log Hours",
  //   path: "/log-hours",
  // },

  {
    title: "TimeSheet",
    path: "/log-hours",
  },
  {
    title: "Attendance",
    path: "/Attendance",
  },
];

export const regularRegistrationMenu: Array<{ title: string; path: string; tool?: string }> = [
  {
    title: "GSTR-1/IFF",
    path: "",
  },
  {
    title: "GSTR-1A",
    path: "",
  },
  {
    title: "GSTR-2X",
    path: "",
  },
  {
    title: "GSTR-3B",
    path: "",
  },
  {
    title: "CMP-08",
    path: "",
    tool: "For the FY 2017-18 and 2018-19, taxpayers under the GST Composition Scheme were required to file GSTR-4 as the quarterly return. This form was later replaced by CMP-08, starting from the FY 2019-20 onwards.",
  },
  {
    title: "GSTR-4",
    path: "",
    tool: "For the FY 2017-18 and 2018-19, taxpayers under the GST composition scheme were required to file GSTR-9A as the annual return. This form was later replaced by GSTR-4, starting from the FY 2019-20 onwards.",
  },
  {
    title: "GSTR-9",
    path: "",
  },
  {
    title: "GSTR-9C",
    path: "",
  },
];

export const regularRegistrationDashboardMenu: Array<{
  title: string;
  path: string;
  type: string;
}> = [
    {
      title: "GSTR-1/IFF",
      path: "",
      type: "GSTR1",
    },
    {
      title: "GSTR-1A",
      path: "",
      type: "GSTR1A"
    },
    {
      title: "GSTR-2X",
      path: "",
      type: "GSTR2X",
    },
    {
      title: "GSTR-3B",
      path: "",
      type: "GSTR3B",
    },
    {
      title: "CMP-08",
      path: "",
      type: "CMP08",
    },
    {
      title: "GSTR-4",
      path: "",
      type: "GSTR4X",
    },
    {
      title: "GSTR-9",
      path: "",
      type: "GSTR9",
    },
    {
      title: "GSTR-9C",
      path: "",
      type: "GSTR9C",
    },
    {
      title: "GSTR-8",
      path: "",
      type: "GSTR8",
    },
    {
      title: "GSTR-7",
      path: "",
      type: "GSTR7",
    },
  ];

export const taskViewMenu: Array<{ id: string; label: string }> = [
  { id: "details", label: "Details" },
  { id: "udin", label: "UDIN" },
  { id: "description", label: "Description" },
  { id: "iPro", label: "IPro" },
  { id: "checklists", label: "Checklists" },
  // { id: "removedmilestones", label: "removedMilestones" },
  { id: "stageofwork", label: "Milestones" },
  { id: "comments", label: "Comments" },
  { id: "expenditure", label: "Expenditure" },
  { id: "subtasks", label: "Sub Tasks" },
  { id: "attachments", label: "Attachments" },
  { id: "loghours", label: "Log Hours" },
  { id: "timeline", label: "Timeline" },
  { id: "approvals", label: "Approvals" },
  { id: "events", label: "Events" },
  { id: "documents", label: "Quantum" },
  { id: "activity", label: "Activity" },
];

export const CLIENT_CATEGORIES: Array<{
  label: string;
  value: string;
  subCategories?: Array<{ label: string; value: string }>;
}> = [
    { label: "Individual", value: "individual" },
    { label: "Hindu Undivided Family", value: "huf" },
    {
      label: "Partnership Firm",
      value: "partnership_firm",
    },
    {
      label: "Limited Liability Partnership",
      value: "llp",
      // subCategories: [
      //   { label: "Indian", value: "indian" },
      //   { label: "Foreign", value: "foreign" },
      // ],
    },

    {
      label: "Company",
      value: "company",
      subCategories: [
        { label: "OPC", value: "opc" },
        { label: "Private Limited", value: "private" },
        { label: "Public Limited", value: "public" },
        { label: "Government", value: "government" },
        { label: "Section-8", value: "sec_8" },
        { label: "Foreign", value: "foreign" },
      ],
    },
    {
      label: "Association of Persons",
      value: "aop",
    },
    {
      label: "Body of Individuals",
      value: "boi",
    },
    {
      label: "Trust",
      value: "trust",
      subCategories: [
        { label: "Public Trust", value: "public_trust" },
        {
          label: "Private Discretionary Trust",
          value: "private_discretionary_trust",
        },
      ],
    },
    {
      label: "Government",
      value: "government",
      subCategories: [
        { label: "State", value: "state" },
        { label: "Central", value: "central" },
      ],
    },
    {
      label: "Local Authority",
      value: "local_authority",
    },
    {
      label: "Artificial Juridical Person",
      value: "artificial_judicial_person",
    },
    // {
    //   label: "Society",
    //   value: "society",
    //   subCategories: [
    //     { label: "Society", value: "society" },
    //     { label: "Co-Operative Society", value: "cooperative_society" },
    //   ],
    // },

    // {
    //   label: "Corporations",
    //   value: "corporations",
    // },
  ];

export enum FormType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
}

export enum RecurringFrequency {
  CUSTOM = "custom",
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
  HALF_YEARLY = "half_yearly",
  YEARLY = "yearly",
}

export enum ReceiptStatus {
  CREATED = "CREATED",
  CANCELLED = "CANCELLED",
}

export enum RecurringFrequencyService {
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
  HALF_YEARLY = "half_yearly",
  YEARLY = "yearly",
}

export enum DateTypes {
  FROM_START_DATE = "from_start_date",
  TO_START_DATE = "to_start_date",
  START_DATE = "start_date",
  STATUTORY_DATE = "status_date",
  EXPECTED_DATE = "expected_date",
  TO_STATUTORY_DATE = "to_statutory_date",
  FROM_STATUTORY_DATE = "from_statutory_date",
  TO_EXPECTED_DATE = "to_expected_date",
  FROM_EXPECTED_DATE = "from_expected_date",
  UDIN_DATE = "udin_date",
}

export enum FromToDates {
  FROM_DATE = "from_date",
  TO_DATE = "to_date",
}

export enum PriorityEnum {
  HIGH = "high",
  MEDIUM = "medium",
  LOW = "low",
  NONE = "none",
}

export enum SortDirection {
  ASC = "asc",
  DESC = "desc"
}
export enum SORT_DIRECTIONS {
  ASC = "asc",
  DESC = "desc"
}

export enum FILETYPES {
  JPEG = "image/jpeg",
  PNG = "image/png",
  SVG = "image/svg+xml",
  JPG = "image/jpg",
  TEXT = "text/plain",
  CSV = "text/csv",
  MP4 = "video/mp4",
  AUDIO = "audio/mpeg",
  WEBM = "video/webm",
  PDF = "application/pdf",
  ZIP1 = "application/zip",
  ZIP2 = "application/vnd.android.package-archive",
  SHEET1 = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  SHEET2 = "application/vnd.oasis.opendocument.spreadsheet",
  SHEET3 = "application/vnd.ms-excel",
  SHEET4 = "application/vnd.google-apps.spreadsheet",
  DOC1 = "application/vnd.oasis.opendocument.text",
  DOC2 = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  DOC3 = "application/msword",
  DOC4 = "application/vnd.google-apps.document",
  PRESENTATION1 = "application/vnd.oasis.opendocument.presentation",
  PRESENTATION2 = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  PRESENTATION3 = "application/vnd.ms-powerpoint",
}

export enum CLOUDFILETYPES {
  XLSX = 'xlsx',
  PDF = 'pdf',
  PNG = 'png',
}

export enum Reminders {
  "BEFORE_15_MINUTES" = "before_15_minutes",
  "BEFORE_30_MINUTES" = "before_30_minutes",
  "BEFORE_1_HOUR" = "before_1_hour",
  "BEFORE_2_HOUR" = "before_2_hours",
  "BEFORE_1_DAY" = "before_1_day",
}

export enum DateFilters {
  TODAY = "today",
  YESTERDAY = "yesterday",
  THIS_WEEK = "this_week",
  LAST_WEEK = "last_week",
  THIS_MONTH = "this_month",
  LAST_MONTH = "last_month",
  // OVERDUE = "overdue",
  CUSTOM = "custom",
}

export enum TaskPaymentStatus {
  UNBILLED = "UNBILLED",
  BILLED = "BILLED",
}

export enum TaskStatus {
  TODO = "todo",
  IN_PROGRESS = "in_progress",
  ON_HOLD = "on_hold",
  UNDER_REVIEW = "under_review",
  COMPLETED = "completed",
  // TERMINATED = "terminated",
  // DELETED = "deleted",
  // TERMINATED = "terminated",
}
export enum TaskStatusFilter {
  TODO = "todo",
  DUE_TODO = "todo_overdue",

  IN_PROGRESS = "in_progress",
  DUE_IN_PROGRESS = "in_progress_overdue",

  ON_HOLD = "on_hold",
  DUE_ON_HOLD = "on_hold_overdue",

  UNDER_REVIEW = "under_review",
  DUE_UNDER_REVIEW = "under_review_overdue",

  COMPLETED = "completed",
  Completed_Behind_Schedule = "completed_overdue",

  // DUE_COMPLETED = "completed",
  // TERMINATED = "terminated",
  // DELETED = "deleted",
  // TERMINATED = "terminated",
}

export enum TaskStatusReports {
  TODO = "todo",
  IN_PROGRESS = "in_progress",
  ON_HOLD = "on_hold",
  UNDER_REVIEW = "under_review",
  COMPLETED = "completed",
  TERMINATED = "terminated",
  DELETED = "deleted",
}

export enum ProformaStatus {
  CREATED = 'created',
  CONVERTED = 'converted',
  CANCELLED = 'cancelled',
  CLOSED = 'closed',
}
export enum SubTaskStatusReports {
  TODO = "todo",
  IN_PROGRESS = "in_progress",
  ON_HOLD = "on_hold",
  // UNDER_REVIEW = "under_review",
  COMPLETED = "completed",
  TERMINATED = "terminated",
  DELETED = "deleted",
}

export enum TaskStatusReportsActive {
  TODO = "todo",
  IN_PROGRESS = "in_progress",
  ON_HOLD = "on_hold",
  UNDER_REVIEW = "under_review",
  COMPLETED = "completed",
}

export enum SubTaskStatus {
  TODO = "todo",
  IN_PROGRESS = "in_progress",
  ON_HOLD = "on_hold",
  COMPLETED = "completed",
}

export enum REPORT {
  user_name = "User",
  full_name = "User",
  todo = "Todo",
  in_progress = "In Progress",
  on_hold = "On Hold",
  Hold = "On Hold",
  under_review = "Under Review",
  terminated = "Terminated",
  completed = "Completed",
  deleted = "Deleted",
  // done_completed = "Completed",
  total = "Total",
  amounttotal = "Total",
  wip = "In Progress",
  category = "Service Category ",
  sub_category = "Service Sub-Category",
  clientname = "Client",
  clientname_clientgroupname = "Client / Client Group",
  taskname = "Task Name",
  task_id = "Task ID",
  status = "Status",
  fee = "Service Fee",
  dueDate = "Statutory Due Date",
  daysoverdue = " Overdue By",
  totaltasksassigned = "Assigned Task",
  pureagentpaid = "Pure Agent Paid",
  receipts = "Receipts",
  invoicedate = "Invoice Date",
  receiptdate = "Receipt Date",
  invoiceduedate = "Invoice Due Date",
  invoiceamount = "Invoice Amount",
  invoicenumber = "Invoice #",
  proformaInvoicenumber = "Proforma Invoice #",
  amountrecieved = "Amount Received",
  balancedueamount = "Balance Due",
  receiptamount = "Receipt Amount",
  receiptnumber = "Receipt #",
  pureagent = "Pure Agent",
  additionalchargers = "Additional Charges",
  tasknumber = "Task ID",
  dueamount = "Due Amount",
  clientid = "Client ID",
  receipttype = "Receipt Type",
  receiptcreateddate = "Receipt Created Date",
  Client = "Client",
  Category = "Category",
  completed_count = "Completed Task",
  terminated_count = "Terminated Task",
  deleted_count = "Deleted Task",
  billing_entity = "Billing Entity",
}

export const STATES = [
  {
    value: "AN",
    label: "Andaman and Nicobar Islands",
  },
  {
    value: "AP",
    label: "Andhra Pradesh",
  },
  {
    value: "AR",
    label: "Arunachal Pradesh",
  },
  {
    value: "AS",
    label: "Assam",
  },
  {
    value: "BR",
    label: "Bihar",
  },
  {
    value: "CH",
    label: "Chandigarh",
  },
  {
    value: "CT",
    label: "Chhattisgarh",
  },
  {
    value: "DN",
    label: "Dadra and Nagar Haveli",
  },
  {
    value: "DD",
    label: "Daman and Diu",
  },
  {
    value: "DL",
    label: "Delhi",
  },
  {
    value: "GA",
    label: "Goa",
  },
  {
    value: "GJ",
    label: "Gujarat",
  },
  {
    value: "HR",
    label: "Haryana",
  },
  {
    value: "HP",
    label: "Himachal Pradesh",
  },
  {
    value: "JK",
    label: "Jammu and Kashmir",
  },
  {
    value: "JH",
    label: "Jharkhand",
  },
  {
    value: "KA",
    label: "Karnataka",
  },
  {
    value: "KL",
    label: "Kerala",
  },
  {
    value: "LD",
    label: "Lakshadweep",
  },
  {
    value: "MP",
    label: "Madhya Pradesh",
  },
  {
    value: "MH",
    label: "Maharashtra",
  },
  {
    value: "MN",
    label: "Manipur",
  },
  {
    value: "ML",
    label: "Meghalaya",
  },
  {
    value: "MZ",
    label: "Mizoram",
  },
  {
    value: "NL",
    label: "Nagaland",
  },
  {
    value: "OR",
    label: "Odisha",
  },
  {
    value: "PY",
    label: "Puducherry",
  },
  {
    value: "PB",
    label: "Punjab",
  },
  {
    value: "RJ",
    label: "Rajasthan",
  },
  {
    value: "SK",
    label: "Sikkim",
  },
  {
    value: "TN",
    label: "Tamil Nadu",
  },
  {
    value: "TG",
    label: "Telangana",
  },
  {
    value: "TR",
    label: "Tripura",
  },
  {
    value: "UP",
    label: "Uttar Pradesh",
  },
  {
    value: "UT",
    label: "Uttarakhand",
  },
  {
    value: "WB",
    label: "West Bengal",
  },
];

export const TDS_SECTIONS = [
  { value: "194C", label: "194C" },
  { value: "194H", label: "194H" },
  { value: "194Ⅰ(a)", label: "194Ⅰ(a)" }, // Unicode Roman Numeral "Ⅰ" (U+2160)
  { value: "194Ⅰ(b)", label: "194Ⅰ(b)" },
  { value: "194ⅠB", label: "194ⅠB" },
  { value: "194J", label: "194J" },
  { value: "194M", label: "194M" },
  { value: "194O", label: "194O" },
  { value: "194Q", label: "194Q" },
  { value: "194S", label: "194S" },
  { value: "195", label: "195" }
];

export const TDS_RATES = [
  { value: '0.1', label: '0.1%' },
  { value: '1', label: '1%' },
  { value: '2', label: '2%' },
  { value: '5', label: '5%' },
  { value: '10', label: '10%' },
  { value: '20', label: '20%' },
  { value: '30', label: '30%' },
]

export const CONTACT_PERSON_ROLES = ["Accountant", "Admin", "Staff"];

export const ORGANIZATION_CATEGORIES = [
  {
    label: "CA",
    value: "CA",
  },
  {
    label: "CMS",
    value: "CMS",
  },
  {
    label: "CS",
    value: "CS",
  },
  {
    label: "Law",
    value: "Law",
  },
];

export const ORGANIZATION_TYPES = [
  {
    label: "Sole Proprietorship",
    value: "SOLE_PROPRIETORSHIP",
  },
  {
    label: "Partnership Firm",
    value: "PARTNERSHIP_FIRM",
  },
  {
    label: "LLP",
    value: "LLP",
  },
  {
    label: "Private Limited Company",
    value: "PRIVATE_LIMITED_COMPANY",
  },
];

export const LICENSE_TYPES = [
  {
    label: "Organization Incorporation",
    value: "ORGANIZATION_INCORPORATION",
  },
  {
    label: "Professional Tax",
    value: "PROFESSIONAL_TAX",
  },
  {
    label: "Trade License",
    value: "TRADE_LICENSE",
  },
];

export const COLORS = [
  "#88B151",
  "#F7964F",
  "#673AB7",
  "#64B5F6",
  "#C0FF8C",
  "#FFCDD2",
  "#FF8A65",
  "#FF5252",
  "#FF1744",
  "#D50000",
  "#3F51B5",
  "#2196F3",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#795548",
  "#9E9E9E",
  "#607D8B",
  "#000000",
  "#FFFFFF",
];

export const documentTypes = [
  { label: "Custom", value: "custom" },
  { label: "Aadhaar", value: "aadhar" },
  { label: "PAN", value: "pan" },
  { label: "TAN", value: "tan" },
  { label: "Partnership Deed", value: "patnership_deed" },
  { label: "LLPIN", value: "llpin" },
  { label: "CIN", value: "cin" },
  { label: "Trust Deed", value: "trust-deed" },
  { label: "EPFO", value: "epfo" },
  { label: "ESIC", value: "esic" },
  { label: "Professional Tax", value: "professional_tax" },
  { label: "Trade License", value: "trade_license" },
  { label: "GST", value: "gst" },
  { label: "MSME", value: "msme" },
  { label: "IEC", value: "iec" },
  { label: "Startup India", value: "startup_india" },
];

export const credentialSites = [
  { label: "Custom", value: "custom" },
  {
    label: "Income Tax | e-Filing (PAN)",
    value: "Income Tax | e-Filing (PAN)",
    websiteurl: "https://eportal.incometax.gov.in/iec/foservices/#/login",
  },
  {
    label: "Income Tax | e-Filing (TAN)",
    value: "Income Tax | e-Filing (TAN)",
    websiteurl: "https://eportal.incometax.gov.in/iec/foservices/#/login",
  },
  {
    label: "Income Tax | Traces (Tax Deductor)",
    value: "Income Tax | Traces (Tax Deductor)",
    websiteurl: "https://www.tdscpc.gov.in/app/login.xhtml?usr=Ded",
  },
  {
    label: "Income Tax | Traces (Tax Payer)",
    value: "Income Tax | Traces (Tax Payer)",
    websiteurl: "https://www.tdscpc.gov.in/app/login.xhtml?usr=Tpy",
  },
  {
    label: "Income Tax | Traces (PAO)",
    value: "Income Tax | Traces (PAO)",
    websiteurl: "https://www.tdscpc.gov.in/app/login.xhtml?usr=Pao",
  },
  {
    label: "Income Tax | Reporting Portal",
    value: "Income Tax | Reporting Portal",
    websiteurl: "https://report.insight.gov.in/reporting-webapp/login",
  },
  {
    label: "GST | e-Filing",
    value: "GST | e-Filing",
    websiteurl: "https://services.gst.gov.in/services/login",
  },
  {
    label: "GST | e-Invoice",
    value: "GST | e-Invoice",
    websiteurl: "https://services.gst.gov.in/services/login?flag=einvoice",
  },
  {
    label: "GST | e-Way Bill",
    value: "GST | e-Way Bill",
    websiteurl: "https://ewaybillgst.gov.in/Login.aspx",
  },
  {
    label: "MCA | Version 2",
    value: "MCA | Version 2",
    websiteurl: "https://www.mca.gov.in/mcafoportal/login.do",
  },
  {
    label: "MCA | Version 3",
    value: "MCA | Version 3",
    websiteurl: "https://www.mca.gov.in/content/mca/global/en/foportal/fologin.html",
  },
  {
    label: "EPFO | Employer",
    value: "EPFO | Employer",
    websiteurl: "https://unifiedportal-emp.epfindia.gov.in/epfo/",
  },
  {
    label: "ESIC | Employer",
    value: "ESIC | Employer",
    websiteurl: "https://www.esic.in/EmployerPortal/ESICInsurancePortal/Portal_Loginnew.aspx",
  },
  { label: "DGFT", value: "DGFT", websiteurl: "https://www.dgft.gov.in/CP/" },
  { label: "ICEGATE", value: "ICEGATE", websiteurl: "https://foservices.icegate.gov.in/#/login" },
];

export enum dscTypes {
  // Individual_DSC_Using_aadhar = 'Individual_DSC_ Using_aadhar',
  // Organization_DSC_Gst_holder = 'Organization_DSC_ Gst_holder',
  Apply_DSC_Individual = "Apply_DSC_Individual",
  // Apply_DSC_Organization= 'Apply_DSC_Organization'
}

export const paymentMethods = {
  UPI: "UPI",
  CASH: "Cash",
  BANK_TRANSFER_NEFT_RTGS_IMPS: "BankTransfer (NEFT | RTGS | IMPS)",
  CHEQUE: "Cheque",
  DEBIT_CARD: "Debit Card",
  CREDIT_CARD: "Credit Card",
};

export const gstReturnsCategories = [
  { label: "Regular Taxpayer", value: "regular_taxpayer" },
  { label: "Tax Collector", value: "tax_collector" },
  { label: "Tax Deductor", value: "tax_deductor" },
];

export enum RegularTaxHeadings {
  GSTR1 = "GSTR-1/IFF: Details of Outward Supplies of Goods or Services",
  GSTR1A = "GSTR-1A: Amendment of Outward Supplies of Goods or Services for Current Tax Period",
  GSTR2X = "GSTR-2X: Statement of TDS & TCS Credit Received",
  GSTR3B = "GSTR-3B: Details of Outward Supplies & Inward Supplies liable to Reverse Charge",
  CMP08 = "CMP-08: Statement for payment of Self-Assessed Tax",
  GSTR4 = "GSTR-4: Annual Return of Registered Person who has opted for Composition Levy",
  GSTR9 = "GSTR-9: Annual Return",
  GSTR9C = "GSTR-9C: Reconciliation Statement",
  GSTR7 = "GSTR-7: Return for Tax Deducted at Source",
  GSTR8 = "GSTR-8: Statement for Tax Collection at Source",
  GST_NOT_VERIFIED = "For a seamless, smooth, and error-free sync of your Client's GST Returns data, verifying their GSTIN upfront is highly recommended in the profile section. This preliminary step serves as a data integrity check, guaranteeing that the retrieved information pertains to the correct taxpayer. By taking this precaution, you can avoid potential syncing issues that might arise later on.",
}

export enum RstReturnsCategories {
  REGULAR_TAXPAYER = "regular_taxpayer",
  TAX_COLLECTOR = "tax_collector",
  TAX_DEDUCTOR = "tax_deductor",
}

export const TASK_TYPES = [
  { label: "All", value: "all" },
  { label: "Main Task", value: "main_task" },
  { label: "Sub Task", value: "sub_task" },
];

export const TaskFields = [
  { label: "Status", value: "status" },
  { label: "Start Date", value: "start_date" },
  { label: "Statutory Due Date", value: "due_date" },
  { label: "Expected Completion Date", value: "expected_completion_date" },
  { label: "Priority", value: "priority" },
  { label: "Add user", value: "add_user" },
  { label: "Update User", value: "update_user" },
  { label: "Remove User", value: "remove_user" },
  { label: "Add Task Leader", value: "add_task_leader" },
  { label: "Update Task Leader", value: "update_task_leader" },
  { label: "Remove Task Leader", value: "remove_task_leader" },
  { label: "Billing Type", value: "billing_type" },
];
export const RecurringFields = [
  { label: "Priority", value: "priority" },
  { label: "Add Label", value: "add_label" },
  { label: "Update Labels", value: "update_label" },
  { label: "Remove Label", value: "remove_label" },
  { label: "Add user", value: "add_user" },
  { label: "Update User", value: "update_user" },
  { label: "Remove User", value: "remove_user" },
  { label: "Add Task Leader", value: "add_task_leader" },
  { label: "Update Task Leader", value: "update_task_leader" },
  { label: "Remove Task Leader", value: "remove_task_leader" },
  { label: "Billing Type", value: "billing_type" },
  { label: "Update Approval", value: "update_approval" },
  { label: "Update Dates", value: "update_dates" },
  { label: "Update UDIN", value: "update_udin" },
];
export const TASK_RECURRING_TYPES = [
  { label: "All", value: "all" },
  { label: "Recurring", value: "recurring" },
  { label: "Non - Recurring", value: "non-recurring" },
];

export const BILLING_ENTITY_TAGS = [
  { label: "Chartered Accountants", value: "ca" },
  { label: "Cost and Management Accountants", value: "cma" },
  { label: "Company Secretaries", value: "cs" },
  { label: "Tax Consultants", value: "tc" },
  { label: "Custom", value: "custom" },
];

export const STORAGE_SYSTEMS = [
  { label: "Vider Storage", value: "AMAZON" },
  { label: "One Drive", value: "MICROSOFT" },
  { label: "Bharath Cloud", value: "BHARATHCLOUD" },
];

export enum BulkFromToDates {
  START_FROM_DATE = "start_from_date",
  START_TO_DATE = "start_to_date",
  DUE_FROM_DATE = "due_from_date",
  DUE_TO_DATE = "due_to_date",
  COMPLETED_FROM_DATE = "completed_from_date",
  COMPLETED_TO_DATE = "completed_to_date",
  CREATED_FROM_DATE = "created_from_date",
  CREATED_TO_DATE = "created_to_date",
}

export enum InvoiceStatus {
  INVOICE = "INVOICE",
  PROFORMA = "PROFORMA",
}

export enum DOCUMENT_TYPES {
  KYB = "kyb",
  TASK = "task",
  GENERAL = "general"
}
